import React, { useState, useEffect, useMemo } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import PageBuilder from "@/components/pageBuilder"
import { Collapse } from "react-collapse"

const ProductsPage = ({ data }) => {
  const products = data.allSanityMerchandise.nodes
  const storePage = data.storePage

  const publishedProducts = useMemo(() => {
    return products.filter(product => !product._id.startsWith("drafts."))
  }, [products])

  const [query, setQuery] = useState("")
  const [selectedFamilies, setSelectedFamilies] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [filteredProducts, setFilteredProducts] = useState(publishedProducts)
  const [isFiltersVisible, setIsFiltersVisible] = useState(false)
  const [sortOption, setSortOption] = useState("")

  const productFamilies = useMemo(() => {
    return [
      ...new Set(
        publishedProducts.map(p => p.productFamily?.title).filter(Boolean)
      ),
    ]
  }, [publishedProducts])

  const productCategories = useMemo(() => {
    return [
      ...new Set(publishedProducts.map(p => p.category?.title).filter(Boolean)),
    ]
  }, [publishedProducts])

  useEffect(() => {
    const filtered = publishedProducts.filter(product => {
      const matchesQuery = product.title.toLowerCase().includes(query)
      const matchesFamily =
        selectedFamilies.length === 0 ||
        selectedFamilies.includes(product.productFamily?.title)
      const matchesCategory =
        selectedCategories.length === 0 ||
        selectedCategories.includes(product.category?.title)

      return matchesQuery && matchesFamily && matchesCategory
    })

    const sortedFiltered = [...filtered].sort((a, b) => {
      // First, compare in-stock status
      if (a.quantity > 0 && b.quantity === 0) {
        return -1 // a comes before b
      } else if (a.quantity === 0 && b.quantity > 0) {
        return 1 // b comes before a
      } else {
        // Both have same in-stock status
        // Next, handle comingSoon status
        if (a.comingSoon && !b.comingSoon) {
          return 1 // a comes before b
        } else if (!a.comingSoon && b.comingSoon) {
          return 1 // b comes before a
        } else {
          // Both have same in-stock and comingSoon status
          // Sort based on sortOption
          if (sortOption === "priceLowToHigh") {
            return (a.price || 0) - (b.price || 0)
          } else if (sortOption === "priceHighToLow") {
            return (b.price || 0) - (a.price || 0)
          } else if (sortOption === "aToZ") {
            return a.title.localeCompare(b.title)
          } else if (sortOption === "newestToOldest") {
            return new Date(b._createdAt) - new Date(a._createdAt)
          } else if (sortOption === "oldestToNewest") {
            return new Date(a._createdAt) - new Date(b._createdAt)
          } else {
            return 0 // Default order
          }
        }
      }
    })

    setFilteredProducts(sortedFiltered)
  }, [
    query,
    selectedFamilies,
    selectedCategories,
    sortOption,
    publishedProducts,
  ])

  const handleSearch = event => {
    setQuery(event.target.value.toLowerCase())
  }

  const handleFamilyFilter = (family, isChecked) => {
    const newSelectedFamilies = isChecked
      ? [...selectedFamilies, family]
      : selectedFamilies.filter(f => f !== family)
    setSelectedFamilies(newSelectedFamilies)
  }

  const handleCategoryFilter = (category, isChecked) => {
    const newSelectedCategories = isChecked
      ? [...selectedCategories, category]
      : selectedCategories.filter(c => c !== category)
    setSelectedCategories(newSelectedCategories)
  }

  const Hit = React.memo(({ product }) => {
    const { title, mainImage, price, excerpt, slug, quantity, comingSoon } =
      product

    return (
      <Link
        to={`/store/${slug.current}`}
        className="flex flex-col justify-between p-4 border dark:border-zinc-700 rounded-md h-full bg-white dark:bg-zinc-800"
      >
        <div
          className="relative bg-cover bg-center h-40"
          style={{
            backgroundImage: `url(${
              mainImage?.asset?.url || "/default-image.png"
            })`,
          }}
        >
          {/* Coming Soon Badge */}
          {comingSoon && (
            <div className="absolute top-0 left-0 bg-[#F7B334] text-white text-xs font-bold px-2 py-1">
              COMING SOON
            </div>
          )}
          {/* Sold Out Badge */}
          {quantity === 0 && !comingSoon && (
            <div className="absolute top-0 left-0 bg-gray-600 text-white text-xs font-bold px-2 py-1">
              SOLD OUT
            </div>
          )}
        </div>
        <h5 className="text-xs uppercase tracking-wide font-bold mt-2">
          {title}
        </h5>
        <p className="text-xs font-normal mt-2">{excerpt}</p>

        {/* Price or Status */}
        <div className="text-indigo-600 font-semibold text-xs mt-2">
          {comingSoon ? (
            <span className="text-[#F7B334] uppercase tracking-widest">
              COMING SOON
            </span>
          ) : quantity === 0 ? (
            <span className="text-gray-600 uppercase tracking-widest">
              <strike>SOLD OUT</strike>
            </span>
          ) : price ? (
            `$${price}`
          ) : (
            "N/A"
          )}
        </div>
      </Link>
    )
  })

  return (
    <div className="text-zinc-900 dark:text-zinc-200 bg-white dark:bg-zinc-900">
      <Seo
        title="Imagination Outfitters"
        description="Your destination for innovative tools, wearables, and gear designed for creative explorers and thinkers. Each product blends practicality with curiosity to inspire ingenuity and discovery in every pursuit. From versatile storage solutions to inspiring art supplies, our collection equips you to imagine, create, and explore with purpose and passion."
        defer={false}
      />

      {/* Page Builder Component */}
      <section>
        <PageBuilder
          pageBuilder={storePage.pageBuilder}
          _rawPageBuilder={storePage._rawPageBuilder}
        />
      </section>

      <div className="container mx-auto p-6">
        {/* Search and Sort */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Search products"
            value={query}
            onChange={handleSearch}
            className="border p-2 rounded w-full dark:border-zinc-700 bg-white dark:bg-zinc-900"
          />
          <div className="flex justify-between items-center mt-4">
            {/* Toggle Filters */}
            <button
              onClick={() => setIsFiltersVisible(!isFiltersVisible)}
              className="border-zinc-400 border-2 rounded px-3 py-2 text-xxs font-bold"
            >
              {isFiltersVisible ? "Hide Filters" : "Show Filters"}
            </button>

            {/* Sort Options */}
            <div>
              <label htmlFor="sort" className="mr-2 text-xxs font-bold">
                Sort by:
              </label>
              <select
                id="sort"
                value={sortOption}
                onChange={e => setSortOption(e.target.value)}
                className="border p-2 rounded text-xxs dark:border-zinc-700 bg-white dark:bg-zinc-900"
              >
                <option value="">Default</option>
                <option value="priceLowToHigh">Price low to high</option>
                <option value="priceHighToLow">Price high to low</option>
                <option value="aToZ">A to Z</option>
                <option value="newestToOldest">Newest to Oldest</option>
                <option value="oldestToNewest">Oldest to Newest</option>
              </select>
            </div>
          </div>
        </div>

        {/* Filters */}
        <Collapse isOpened={isFiltersVisible}>
          <div className="p-4 border rounded mb-4 bg-gray-100 flex-col  dark:bg-zinc-800">
            {/* Collections */}
            <div className="mb-4 lg:flex space-x-4">
              <h3 className="text-xxs font-bold mb-2">Collections</h3>
              {productFamilies.map(
                family =>
                  family && (
                    <label key={family} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 mr-2"
                        value={family}
                        onChange={e =>
                          handleFamilyFilter(family, e.target.checked)
                        }
                      />
                      <span className="text-xxs">{family}</span>
                    </label>
                  )
              )}
            </div>

            {/* Categories */}
            <div
              style={{ position: "relative", zIndex: 2 }}
              className="lg:flex space-x-4 border-t pt-4"
            >
              <h3 className="text-xxs font-bold mb-2">Categories</h3>
              {productCategories.map(
                category =>
                  category && (
                    <label key={category} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 mr-2"
                        value={category}
                        onChange={e =>
                          handleCategoryFilter(category, e.target.checked)
                        }
                      />
                      <span className="text-xxs">{category}</span>
                    </label>
                  )
              )}
            </div>
          </div>
        </Collapse>

        {/* Products Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {filteredProducts.map(product => (
            <Hit key={product.id} product={product} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProductsPage

export const query = graphql`
  query ProductQuery {
    allSanityMerchandise {
      nodes {
        _id
        id
        _createdAt
        title
        slug {
          current
        }
        mainImage {
          asset {
            url
          }
        }
        price
        excerpt
        productFamily {
          title
        }
        sku
        quantity
        weight
        taxable
        shippable
        localDeliveryOnly
        comingSoon
        category {
          id
          title
        }
      }
    }
    storePage: sanitySiteSettings(title: { eq: "Store" }) {
      title
      _id
      ...PageBuilderHome
    }
  }
`
